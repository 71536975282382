<template>
  <a-modal title="详情" :width="800" :visible="visible" :destroyOnClose="true" @cancel="handleCancel" :footer="null">
    <a-spin :spinning="confirmLoading">
      <a-row>
        <a-form layout="inline">
          <a-col>
            <!-- 第1行详情 -->
            <a-row>
              <a-divider>订单信息</a-divider>
              <a-col :md="12" :sm="24">
                <a-form-item label="订单类型">
                  <span>{{ details.orderType == 1 ? '普通订单' : detail.orderType == 2 ? '拍图订单' : '' }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="订单状态">
                  <span>
                    {{
                      details.orderStatus == 1
                        ? '已下单'
                        : details.orderStatus == 2
                          ? '付款成功'
                          : details.orderStatus == 3
                            ? '已发货'
                            : details.orderStatus == 4
                              ? '已收货'
                              : details.orderStatus == 5
                                ? '已到账'
                                : details.orderStatus == 6
                                  ? '已取消'
                                  : details.orderStatus == 7
                                    ? '退款成功'
                                    : details.orderStatus == 8
                                      ? '退款中'
                                      : details.orderStatus == 9
                                        ? '退款失败'
                                        : ''
                    }}
                  </span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 第3行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="实付款">
                  <span>{{ details.payPrice }} 元</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="邮费">
                  <span>{{ details.postage }} 元</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 第4行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="商家名称">
                  <span>{{ details.storeName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="支付方式">
                  <span>{{ payType[details.payType] }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 第5行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="订单号">
                  <span>{{ details.orderNo }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="售后天数">
                  <span>{{ details.afterSalesDays }} 天</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 第6行详情 -->
            <a-row>
              <a-col :md="12" :sm="24">
                <a-form-item label="下单时间">
                  <span>{{ details.createTime }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-if="details.remark">
              <a-col :md="24" :sm="24">
                <a-form-item label="下单备注">
                  <span>{{ details.remark }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 退款信息详情 -->
            <a-row v-if="details.orderStatus == 7 || details.orderStatus == 8 || details.orderStatus == 9">
              <a-divider>退款信息</a-divider>
              <a-col :md="12" :sm="24">
                <a-form-item label="退款类型">
                  <span>{{ details.refundType == 1 ? '退款' : details.refundType == 2 ? '退货退款' : '' }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="退款状态">
                  <span v-if="details.refundType == 1">
                    {{
                      details.refund.status == 1
                        ? '申请退款'
                        : details.refund.status == 2
                          ? '商家同意退款'
                          : details.refund.status == 3
                            ? '商家拒绝退款'
                            : ''
                    }}
                  </span>
                  <span v-if="details.refundType == 2">
                    {{
                      details.refund.status == 1
                        ? '申请退货退款'
                        : details.refund.status == 2
                          ? '商家同意退货退款'
                          : details.refund.status == 3
                            ? '用户发货'
                            : details.refund.status == 4
                              ? '商家确认收货'
                              : details.refund.status == 5
                                ? '商家拒绝退货退款'
                                : ''
                    }}
                  </span>
                </a-form-item>
              </a-col>
              <a-col
                :md="12"
                :sm="24"
                v-if="details.refundType == 2 && (details.refund.status == 3 || details.refund.status == 4)"
              >
                <a-form-item label="退货物流公司">
                  <span>{{ details.refund.comLogistics }}</span>
                </a-form-item>
              </a-col>
              <a-col
                :md="12"
                :sm="24"
                v-if="details.refundType == 2 && (details.refund.status == 3 || details.refund.status == 4)"
              >
                <a-form-item label="退货物流单号">
                  <span>{{ details.refund.comCourierId }}</span>
                </a-form-item>
              </a-col>
              <a-col
                :md="12"
                :sm="24"
                v-if="details.refundType == 2 && (details.refund.status == 3 || details.refund.status == 4)"
              >
                <a-form-item label="退货物流公司单号">
                  <span>{{ details.refund.comLogisticsCode }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" v-if="details.refund.refundImages">
                <a-form-item label="申请配图">
                  <viewer>
                    <img
                      v-for="(item, index) in details.refund.refundImages.split(',')"
                      :key="index"
                      :src="item"
                      alt=""
                      style="padding: 5px; border: 0.5px solid #ccc; margin-left: 5px"
                    />
                  </viewer>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="退款原因">
                  <span>{{ details.refund.refundReason }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="退款说明">
                  <span>{{ details.refund.refundExplain }}</span>
                </a-form-item>
              </a-col>
              <a-col
                :md="24"
                :sm="24"
                v-if="
                  (details.refundType == 1 && details.refund.status == 3) ||
                  (details.refundType == 2 && details.refund.status == 5)
                "
              >
                <a-form-item label="商家拒绝原因">
                  <span>{{ details.refund.refuseReason }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 收货信息 -->
            <a-row v-if="details.orderAddress">
              <a-divider>收货信息</a-divider>
              <a-col :md="12" :sm="24">
                <a-form-item label="姓名">
                  <span>{{ details.orderAddress.consigneeUsername }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="手机号">
                  <span>{{ details.orderAddress.consigneeMobile }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="省份">
                  <span>{{ details.orderAddress.provinceName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="城市">
                  <span>{{ details.orderAddress.cityName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="地区">
                  <span>{{ details.orderAddress.areaName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="详细地址">
                  <span>{{ details.orderAddress.address }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 商品信息 -->
            <a-row>
              <a-divider>商品信息</a-divider>
              <a-col :md="24" :sm="24">
                <a-table :columns="columns" bordered :data-source="data" :scroll="{ x: 580 }" :pagination="false">
                  <!-- 商品图片插槽 -->
                  <span slot="goodsImage" slot-scope="text">
                    <viewer :images="[text]">
                      <img :src="text" />
                    </viewer>
                  </span>
                  <!-- 规格图片插槽 -->
                  <span slot="specImage" slot-scope="text">
                    <viewer :images="[text]">
                      <img :src="text" />
                    </viewer>
                  </span>
                  <span slot="refundNum" slot-scope="text, record">
                    {{ record.buyNum - record.surplusNum }}
                  </span>
                </a-table>
              </a-col>
            </a-row>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { orderDetail } from '@/api/modular/mallLiving/orderAdmin'
import { config } from '@/config'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    align: 'center',
    dataIndex: 'goodsName',
    key: 'goodsName',
  },
  {
    title: '商品图片',
    dataIndex: 'goodsImage',
    key: 'goodsImage',
    align: 'center',
    scopedSlots: { customRender: 'goodsImage' },
  },
  {
    title: '规格',
    dataIndex: 'specName',
    key: 'specName',
    align: 'center',
  },
  {
    title: '规格图片',
    dataIndex: 'specImage',
    key: 'specImage',
    align: 'center',
    scopedSlots: { customRender: 'specImage' },
  },
  {
    title: '购买数量',
    dataIndex: 'buyNum',
    key: 'buyNum',
    align: 'center',
  },
  {
    title: '退货数量',
    dataIndex: 'refundNum',
    key: 'refundNum',
    align: 'center',
    scopedSlots: {
      customRender: 'refundNum',
    },
  },
  {
    title: '单价（元）',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    align: 'center',
  },
]
export default {
  data() {
    return {
      payType: config.payType,
      columns,
      data: [],
      visible: false, //modal框显示状态
      confirmLoading: false,
      id: '',
      details: {},
      form: this.$form.createForm(this),
    }
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true

      //获取详情数据
      orderDetail({
        id: record.id,
        orderType: 1,
      })
        .then((res) => {
          this.confirmLoading = true
          if (res.success) {
            this.details = res.data
            this.data = res.data.goodsList
            this.confirmLoading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.confirmLoading = false
          }, 5000)
        })
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
      setTimeout(() => {
        this.details = {} //关闭之后清空
      }, 100)
    },
  },
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;
  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }
  td {
    border: 2px solid rgb(228, 225, 225);
    padding: 7px;
    font-size: 15px;
    width: 30%;
  }
}
img {
  width: 100px;
}
</style>
