export const config = {
  //支付方式 1:支付宝 2 微信 3 余额 4 h5支付宝 5 h5微信 6 小程序
  payType: {
    1: '支付宝',
    2: '微信',
    3: '余额',
    4: 'h5支付宝',
    5: 'h5微信',
    6: '小程序',
  },
}
